import React, { useEffect, useState } from 'react';
import axios from 'axios';

function PaymentHistory() {
    const [payments, setPayments] = useState([]);

    useEffect(() => {
        axios.get('/api/payments', {headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }})
            .then(response => setPayments(response.data))
            .catch(error => console.error(error));
    }, []);

    return (
        <div>
            <h1>История пополнений</h1>
            <div className="border-table payments">
                <table>
                    <thead>
                    <tr>
                        <th>Payment ID</th>
                        <th>UUID</th>
                        <th>User ID</th>
                        <th>User Login</th>
                        <th>Дата</th>
                        <th>Способ пополнения</th>
                        <th>Сумма</th>
                        <th>Статус</th>
                    </tr>
                    </thead>
                    <tbody>
                    {payments.map(payment => (
                        <tr key={payment.id}>
                            <td>{payment.id}</td>
                            <td>{payment.uuid}</td>
                            <td>{payment.userId}</td>
                            <td>{payment.userLogin}</td>
                            <td>{new Date(payment.date).toLocaleString("ru-RU")}</td>
                            <td>{payment.paymentMethod}</td>
                            <td>{payment.amount}</td>
                            <td>{payment.statusOrder}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default PaymentHistory;