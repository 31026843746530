import React, { useEffect, useState } from 'react';
import axios from 'axios';
import sort_img from './img/sort.svg';

function Manuals() {
    const [manuals, setManuals] = useState([]);
    const [manualsLoaded, setManualsLoaded] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupEdit, setIsPopupEdit] = useState(false);
    const [editId, setEditId] = useState(false);
    const [titleForm, setTitleForm] = useState([]);
    const [priceForm, setPriceForm] = useState([]);
    const [textForm, setTextForm] = useState([]);

    const openPopup = () => {
        setIsPopupEdit(false);
        setIsPopupOpen(true);
    };

    // Функция для закрытия попапа
    const closePopup = () => {
        setIsPopupOpen(false);
        setTitleForm('');
        setPriceForm('');
        setTextForm('');
    };

    useEffect(() => {
        axios.get('/api/manuals', {headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }})
            .then(response => {
                setManuals(response.data);
                setManualsLoaded(true);
            })
            .catch(error => console.error(error));
    }, []);

    const removeManual = (manualId) => {
        if (window.confirm(`Вы уверены, что хотите удалить мануал ${manualId}?`)) {
            axios.delete(`/api/manual/${manualId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
                .then(() => {
                    setManuals(manuals.filter(item => item.id !== manualId));
                })
                .catch(error => console.error(error));
        }
    };

    const handleOverlayClick = (e) => {
        // Проверяем, что клик произошел именно по фону, а не по содержимому попапа
        if (e.target === e.currentTarget) {
            closePopup();
        }
    };

    const addManual = (e) => {
        e.preventDefault();

        if (isPopupEdit) {
            const formData = new FormData();
            formData.append('title', titleForm);
            formData.append('price', priceForm);
            formData.append('text', textForm);

            axios.put(`/api/manual/${editId}`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
                .then(response => {
                    alert('Мануал обновлен');
                    setIsPopupOpen(false);
                    setTitleForm('');
                    setPriceForm('');
                    setTextForm('');
                    axios.get('/api/manuals', {headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }})
                        .then(response => setManuals(response.data))
                        .catch(error => console.error(error));
                })
                .catch(error => {
                    console.error(error);
                    alert('Ошибка обновления мануала');
                });
        } else {
            const formData = new FormData();
            formData.append('title', titleForm);
            formData.append('price', priceForm);
            formData.append('text', textForm);

            axios.post('/api/manuals', formData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
                .then(response => {
                    alert('Мануал добавлен');
                    setIsPopupOpen(false);
                    setTitleForm('');
                    setPriceForm('');
                    setTextForm('');
                    axios.get('/api/manuals', {headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }})
                        .then(response => setManuals(response.data))
                        .catch(error => console.error(error));
                })
                .catch(error => {
                    console.error(error);
                    alert('Ошибка добавления мануала');
                });
        }
    };

    const renderHeader = (label) => (
        <th>
            {label}
        </th>
    );

    const editManual = (manualId) => {
        const manualEdit = manuals.find(item => item.id === manualId);
        setIsPopupOpen(true);
        setIsPopupEdit(true);
        setTitleForm(manualEdit.title);
        setTextForm(manualEdit.text);
        setPriceForm(manualEdit.price);
        setEditId(manualEdit.id)
    };

    const renderManuals = (manuals) => {
        if (manuals.length > 0) {
            return (
                <tbody>
                {manuals.map(manual => (
                    <tr key={manual.id}>
                        <td>{manual.id}</td>
                        <td>{manual.title}</td>
                        <td>{manual.text.slice(0, 50)}</td>
                        <td>{manual.price}</td>
                        <td style={{textAlign: "center"}}>
                            <span className="action-icon" onClick={() => editManual(manual.id)}>
                                <span className="icon">
                                    <svg className="feather feather-edit" fill="none" height="24"
                                         stroke="currentColor" strokeLinecap="round"
                                         strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
                                         width="24" xmlns="http://www.w3.org/2000/svg"><path
                                        d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path
                                        d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/></svg>
                                </span>
                            </span>
                            <span className="action-icon" onClick={() => removeManual(manual.id)}><span
                                className="icon">×</span></span>
                        </td>
                    </tr>
                ))}
                </tbody>
            )
        } else {
            return <tbody>
            <tr>
                <td colSpan={100} style={{textAlign: "center"}}>
                    {manualsLoaded ? 'Список мануалов пуст' : 'Загрузка...'}
                </td>
            </tr>
            </tbody>
        }
    }

    return (
        <div className="manuals">
            <div className="head">
                <div><h1>Мануалы</h1></div>
                <div className="head-buttons">
                    <button onClick={openPopup}>
                        Добавить
                    </button>
                </div>
            </div>

            <div className="border-table">
                <table>
                    <thead>
                    <tr>
                        {renderHeader('ID')}
                        {renderHeader('Заголовок')}
                        {renderHeader('Текст')}
                        {renderHeader('Цена')}
                        {renderHeader('Действия')}
                    </tr>
                    </thead>
                    {renderManuals(manuals)}
                </table>
            </div>
                <div>
                    {isPopupOpen && (
                        <div className="overlay" onClick={handleOverlayClick}>
                            <div className="popup">
                                <div className="crest" onClick={closePopup}>×</div>
                                <h2>{isPopupEdit && (<span>Редактировать</span>) || (
                                    <span>Добавить мануал</span>)}</h2>
                                <form onSubmit={addManual}>
                                    <div>
                                    <label htmlFor="">Заголовок</label>
                                        <input
                                            type="text"
                                            value={titleForm}
                                            placeholder="Заголовок"
                                            onChange={(e) => setTitleForm(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="">Цена</label>
                                        <input
                                            type="text"
                                            value={priceForm}
                                            placeholder="Цена"
                                            onChange={(e) => setPriceForm(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="">Текст</label>
                                        <textarea
                                            value={textForm}
                                            placeholder="Содержимое мануала"
                                            onChange={(e) => setTextForm(e.target.value)}
                                            required
                                        >
                                    </textarea>
                                    </div>
                                    <div className="button-block">
                                        {isPopupEdit && (<button type="submit">Редактировать</button>) || (
                                            <button type="submit">Добавить</button>)}
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </div>
        </div>
    );
}

export default Manuals;