import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import sort_img from './img/sort.svg';
import edit_img from "./img/edit.png";

function Products() {
    const [subcategories, setSubcategories] = useState([]);
    const [subcategoriesLoaded, setSubcategoriesLoaded] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupEdit, setIsPopupEdit] = useState(false);
    const [editId, setEditId] = useState(false);
    const [category, setCategory] = useState('GoogleAds');
    const [subcategory, setSubcategory] = useState('');
    const [price, setPrice] = useState('');

    const openPopup = () => {
        setIsPopupOpen(true);
        setIsPopupEdit(false);
        setSubcategory('');
        setPrice('');
    };

    // Функция для закрытия попапа
    const closePopup = () => {
        setIsPopupOpen(false);
    };

    useEffect(() => {
        axios.get('/api/subcategories', {headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }})
            .then(response => {
                setSubcategories(response.data);
                setSubcategoriesLoaded(true);
            })
            .catch(error => console.error(error));
    }, []);

    const removeSubcategory = (subcategoryId) => {
        if (window.confirm(`Вы уверены, что хотите удалить подкатегорию ${subcategoryId}?`)) {
            axios.delete(`/api/subcategory/${subcategoryId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
                .then(() => {
                    setSubcategories(subcategories.filter(item => item.id !== subcategoryId));
                })
                .catch(error => console.error(error));
        }
    };

    const handleOverlayClick = (e) => {
        // Проверяем, что клик произошел именно по фону, а не по содержимому попапа
        if (e.target === e.currentTarget) {
            closePopup();
        }
    };

    const renderHeader = (key, label) => (
        <th>
            {label}
        </th>
    );

    const sendSubcategory = (e) => {
        e.preventDefault();
        if (isPopupEdit) {
            const formData = new FormData();
            formData.append('title', subcategory);
            formData.append('price', price);

            axios.put(`/api/subcategory/${editId}`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
                .then(response => {
                    alert('Подкатегория успешно обновлена!');
                    setIsPopupOpen(false);
                    setSubcategory('');
                    setPrice('');
                    axios.get('/api/subcategories', {headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }})
                        .then(response => setSubcategories(response.data))
                        .catch(error => console.error(error));
                })
                .catch(error => {
                    console.error(error);
                    alert('Ошибка обновления подкатегории');
                });
        } else {
            const formData = new FormData();
            formData.append('category', category);
            formData.append('title', subcategory);
            formData.append('price', price);

            axios.post('/api/subcategories', formData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
                .then(response => {
                    alert('Подкатегория успешно добавлена!');
                    setSubcategories([...subcategories, response.data.subcategory]);
                    setIsPopupOpen(false);
                    setSubcategory('');
                    setPrice('');
                })
                .catch(error => {
                    console.error(error);
                    alert('Ошибка добавления подкатегории');
                });
        }
    };

    const editSubcategory = (subcategoryId) => {
        const subcategoryEdit = subcategories.find(item => item.id === subcategoryId);
        setIsPopupOpen(true);
        setIsPopupEdit(true);
        setCategory(subcategoryEdit.category);
        setSubcategory(subcategoryEdit.title);
        setPrice(subcategoryEdit.price)
        setEditId(subcategoryEdit.id)
    };

    const renderProducts = (subcategories) => {
        if (subcategories.length > 0) {
            return (
                <tbody>
                {subcategories.map(subcategory => (
                    <tr key={subcategory.id}>
                        <td>{subcategory.id}</td>
                        <td>{subcategory.category}</td>
                        <td>{subcategory.title}</td>
                        <td>{subcategory.price}</td>
                        <td style={{textAlign: "center"}}>
                            <span className="action-icon" onClick={() => editSubcategory(subcategory.id)}>
                                <span className="icon">
                                    <svg className="feather feather-edit" fill="none" height="24"
                                         stroke="currentColor" strokeLinecap="round"
                                         strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
                                         width="24" xmlns="http://www.w3.org/2000/svg"><path
                                        d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path
                                        d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/></svg>
                                </span>
                            </span>
                            <span className="action-icon" onClick={() => removeSubcategory(subcategory.id)}><span
                                className="icon">×</span></span>
                        </td>
                    </tr>
                ))}
                </tbody>
            )
        } else {
            return <tbody>
            <tr>
                <td colSpan={100} style={{textAlign: "center"}}>
                    {subcategoriesLoaded ? 'Список подкатегорий пуст' : 'Загрузка...'}
                </td>
            </tr>
            </tbody>
        }
    }

    return (
        <div>
            <div className="head">
                <div><h1>Подкатегории</h1></div>
                <div className="head-buttons">
                    <button onClick={openPopup}>
                        Добавить
                    </button>
                </div>
            </div>

            <div className="border-table">
                <table>
                    <thead>
                    <tr>
                        {renderHeader('id', 'ID')}
                        {renderHeader('category', 'Категория')}
                        {renderHeader('login', 'Подкатегория')}
                        {renderHeader('price', 'Цена')}
                        <th>Действия</th>
                    </tr>
                    </thead>
                    {renderProducts(subcategories)}
                </table>
            </div>
                <div>
                    {isPopupOpen && (
                        <div className="overlay" onClick={handleOverlayClick}>
                            <div className="popup">
                                <div className="crest" onClick={closePopup}>×</div>
                                <h2>{isPopupEdit && (<span>Редактировать</span>) || (
                                    <span>Добавить подкатегорию</span>)}</h2>
                                <form className="addProduct" onSubmit={sendSubcategory}>
                                    <div>
                                        <label htmlFor="">Категория</label>
                                        <select required value={category} disabled={isPopupEdit ? true : false}
                                                onChange={e => setCategory(e.target.value)}>
                                            <option disabled>Category</option>
                                            <option value="GoogleAds">GoogleAds</option>
                                            <option value="Facebook">Facebook</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="">Подкатегория</label>
                                        <input
                                            type="text"
                                            value={subcategory}
                                            placeholder="Подкатегория"
                                            onChange={(e) => setSubcategory(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="">Цена</label>
                                        <input
                                            type="text"
                                            value={price}
                                            placeholder="Цена за оффер"
                                            onChange={(e) => setPrice(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="button-block">
                                        {isPopupEdit && (<button type="submit">Редактировать</button>) || (
                                            <button type="submit">Добавить</button>)}
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </div>
        </div>
    );
}

export default Products;