import React, { useEffect, useState } from 'react';
import axios from 'axios';

function OrderHistory() {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        axios.get('/api/orders', {headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }})
            .then(response => setOrders(response.data))
            .catch(error => console.error(error));
    }, []);

    const updateStatus = (orderId, status) => {
        axios.put(`/api/order/${orderId}`, { status }, {headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }})
            .then(() => {
                setOrders(orders.map(order => order.orderId === orderId ? { ...order, status } : order));
            })
            .catch(error => console.error(error));
    };

    return (
        <div>
            <h1>История заказов</h1>
            <div className="border-table">
            <table className="orderHistory">
                <thead>
                <tr>
                    <th>Order ID</th>
                    <th>User ID</th>
                    <th>User Login</th>
                    <th>Дата</th>
                    <th>Оффер</th>
                    <th>Количество</th>
                    <th>Цена</th>
                    <th>Статус</th>
                </tr>
                </thead>
                <tbody>
                {orders.map(order => (
                    <tr key={order.orderId}>
                        <td>{order.orderId}</td>
                        <td>{order.userId}</td>
                        <td>{order.userLogin}</td>
                        <td>{new Date(order.date).toLocaleString("ru-RU")}</td>
                        <td>{order.product}</td>
                        <td>{order.count}</td>
                        <td>{order.price}</td>
                        <td>{order.status}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            </div>
        </div>
    );
}

export default OrderHistory;